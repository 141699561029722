import React, { useEffect } from "react"
import { Animated } from "react-animated-css"
import ReactRevealText from "react-reveal-text"
import { isMobileOnly } from "react-device-detect"

import makeStyles from "@material-ui/styles/makeStyles"

import logo from "../images/logo.png"
import Layout from "../components/layout"

const useStyle = makeStyles({
  baseline: {
    fontSize: "3rem",
    textAlign: "center",
  },

  hero: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
  },

  logo: {
    width: "35rem",
    maxWidth: "90vw",
  },

  skills: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  },

  skill: {
    margin: "1rem",
    fontSize: "1.5rem",
    "@media (max-width:600px)": {
      fontSize: "0.9rem",
    },

    "&.big": {
      fontSize: "2rem",
      fontWeight: "bold",

      "@media (max-width:600px)": {
        fontSize: "1.2rem",
      },
    },
  },
})

const IndexPage = () => {
  const [reveal, setReveal] = React.useState(false)

  const classes = useStyle()

  useEffect(() => {
    setTimeout(() => setReveal(true), 150)
  }, [])

  return (
    <Layout page="home">
      <div className={classes.hero}>
        <Animated animationIn="fadeInDown">
          <img
            className={classes.logo}
            src={logo}
            alt="Tech Ex Machina Brand Logo"
          />
        </Animated>

        {!isMobileOnly && (
          <h1 className={classes.baseline}>
            <ReactRevealText show={reveal} text="Trustworthy Tech Partners" />
          </h1>
        )}

        <div className={classes.skills}>
          {[
            "Expertise Technique Fullstack",
            "Audit & Conseil",
            "LEAN Startups",
          ].map((skill, idx) => (
            <Animated
              key={idx}
              animationIn="fadeInUp"
              className={`${classes.skill} big`}
              animationInDelay={1000 + 300 * idx}
            >
              {skill}
            </Animated>
          ))}
        </div>
        <div className={classes.skills}>
          {[
            "Agilité",
            "Infrastructures & Hébergement",
            "IoT",
            "Business Development",
            "Blockchain",
          ].map((skill, idx) => (
            <Animated
              key={idx}
              animationIn="fadeInUp"
              className={classes.skill}
              animationInDelay={1000 + 300 * idx}
            >
              {skill}
            </Animated>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
